.MenuButtonHeader {
  padding-top: 0px !important;
  padding-bottom: 5px !important;
}

#JeuxGauge {
  width: 120px !important;
  height: 40px !important;
}

#PausesGauge {
  width: 120px !important;
  height: 40px !important;
}

#vizy-head {
  padding-top: 8px;
  width: 80%;
  max-width: 80px;
}
