div.swiper-slide.swiper-slide-next {
  margin-bottom: 5px;
}

.swiper-slide {
  max-width: 250px;
}

.video-react-big-play-button {
  visibility: hidden !important;
}

.gameConcentration {
  display: flex;
  flex-direction: column;
  margin: 1em;
  align-items: center;
  padding: 1em;
  background-color: rgb(225, 236, 245);

  .title {
    font-size: 20px;
    margin: 0 0 1em;
    font-family: DINNRoundedRegular;
  }

  .description {
    margin: 20px 0;
  }
}