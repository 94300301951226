.MenuButton {
  width: 20%;
}

#inner>a {
  color: white;
  border-color: white;
}

.ui.menu .item {
  &::before {
    background: none;
  }

  .itemName {
    font-size: 1.2em;
    padding-top: 5px;
  }
}

.ui.basic.button {
  box-shadow: none;
}

#inner>div.item.MenuButtonHeader {
  color: white;
}

#inner {
  display: flex;
  border: 0px solid white;
  background: none;
}

#outer {
  border: 1px solid white;
  position: relative;
  background-image: linear-gradient(#387cc9, #2DA8BE);
  width: 100%
}

#vizy-head {
  padding-top: 8px;
  max-width: 60px;
}

#BurgerButton {
  width: 100px;
}

.crown {
  mask: url("../../../public/images/crown.svg");
  mask-size: cover;
  width: 45px;
  height: 33px;
  padding-bottom: 3px;
  font-family: arial;
  display: flex;
  font-weight: bold;
  font-size: 1.2em;
  align-items: flex-end;
  justify-content: center;
}