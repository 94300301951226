body {
  font-family: arial !important;
}

.outer {
  margin: 0 auto;
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.middle {
  display: table-cell;
  vertical-align: middle;
}

.inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 1500px;
}

.CircularProgressbar {
  max-width: 1000px;
}

.CircularProgressbar-text {
  visibility: hidden;
}

.CenteredText {
  text-align: center;
}

/* Grow */
.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 0.3s;
  transition-property: transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  transform: scale(1.1);
}


/* Push */
@-webkit-keyframes hvr-push {
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes hvr-push {
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.hvr-push {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-push:hover,
.hvr-push:focus,
.hvr-push:active {
  -webkit-animation-name: hvr-push;
  animation-name: hvr-push;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.full-screenable-node {
  height: 100%;
}

.pause {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;

  .text {
    text-align: center;
    margin-top: 30px;
    max-width: 70vh;
  }
}

#dashboard-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 95em;
  height: 100px;
  margin: 1rem 2rem;

  .row {
    margin: 1rem 0;
  }
}

[data-test-id="CircularProgressbarWithChildren"] {
  max-width: 350px;
}

.score-title {
  font-size: 16px;
  font-weight: 700;
  color: #ff9600;
}

.ui.card>.content>.header {
  font-family: DINNRoundedRegular;
  font-size: 2.5em !important;
  font-weight: bold;
  color: #2185d0;
  text-transform: uppercase
}

#dashboard-container .ui.card {
  background-color: #e0f8fd;
  box-shadow: none;
  border-radius: 2em;
  padding: 1em;

  .content>.header:not(.ui) {
    font-size: 3em;
  }
}