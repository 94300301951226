.video-react-video {
  background: white;
  z-index: 1000;
}

.video-react-control-bar {
  font-family: arial;
}

.gameName {
  font-family: DINNRoundedRegular;
  font-size: 2em;
  text-transform: uppercase;
  color: #2185d0;
  padding-bottom: .5em;
  margin: 1em 1em 0.3em;
  border-bottom: solid 1px #2185d0;
  text-align: center;
}

.game {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  color: #145484;
  font-weight: bold;

  .video,
  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em;
  }

  .video {
    width: 500px;
  }

  .buttons {
    button {
      width: 140px;
      text-transform: uppercase;
      border-radius: 2em;
      margin: 0 .5em .5em 0;
    }

    text-align: center;
    margin: 1.5em 0;
  }

  .benefits {
    align-self: stretch;
    background-color: #ffedde;
    color: #e07000;
    border-radius: 1em;
    padding: 1em;

    .benefits1 {
      font-size: 1.5em;
      margin-bottom: .2em;
    }

    .benefits2 {
      font-size: 1.1em;
      line-height: 1em;
    }
  }

  .text {
    font-family: DINNRoundedRegular;
    font-size: 1.2em;

    .summary {
      height: 265px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: #a1d7ed;
      border-radius: 1em;
      padding: 1em;
      max-width: 25em;

      &>div {
        margin-top: .3em;
        display: flex;
        align-items: center;

        i.inverted.circular.icon {
          background-color: #2185d0 !important;
          background-color: #1175c0 !important;
          margin-right: 1em;

          &::before {
            color: #f0d010;
          }
        }

        .textIcon {
          line-height: 1em;
          max-width: 20em;
        }
      }
    }

    .instructions {
      max-width: 23em;

      ol {
        padding: 0 0 0 1em;

        li {
          margin: .3em 0;
        }
      }
    }
  }
}