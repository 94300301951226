#stats-container {
  margin-top: 10%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-end {
  display: inline-block;
  width: auto !important;
  transform: translateX(-50%);
}

.ct-label {
  fill: rgba(0, 0, 0, .6);
  color: rgba(0, 0, 0, .6);
}

#vitality {
  width: 100%;
  height: 200px;

  .ct-series-a .ct-line {
    stroke: #2185d0;
    stroke-width: 2px;
  }

  .ct-series-a .ct-point {
    stroke: #2185d0;
    stroke-width: 5px;
  }

  .ct-series-a .ct-area {
    fill: #2185d0;
  }

  .ct-label.ct-label.ct-horizontal.ct-end {
    position: relative;
    justify-content: flex-end;
    text-align: right;
    transform-origin: 100% 0;
    transform: translate(-100%) rotate(-45deg);
    white-space: nowrap;
  }
}

.scoresPlot {
  width: 100%;

  h3 {
    text-transform: uppercase;
  }

  .graph {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;

    .ct-chart {
      width: 350px;
    }

    .ct-bar {
      stroke-width: 10px;
    }

    .ct-series-a .ct-bar {
      stroke: #2185d0;
    }

    .ct-series-b .ct-bar {
      stroke: lightskyblue;
    }

    .settings {
      display: flex;
      flex-direction: column;

      .ui.vertical.buttons .button {
        margin-bottom: 1px;
      }

      .legend {
        margin-top: 5px;

        .entry {
          display: flex;
          justify-content: center;
          align-items: center;

          div {
            width: 10px;
            height: 10px;
            display: flex;
            align-items: center;

            &.legend-a {
              background-color: #2185d0;
            }

            &.legend-b {
              background-color: lightskyblue;
            }
          }

          span {
            margin-left: 3px;
            text-transform: capitalize;
            font-size: 10px;

            &:not(:last-child) {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}

i.inverted.circular.icon {
  background-color: #2185d0 !important;
}