#tabcontainer {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}


#tabcontainer>div>div.ui.attached.tabular.menu>a.active.item {
  background: #E2ECF6;

}

#tabcontainer>div>div.ui.attached.tabular.menu>a:not(:active) {
  background: #AFCFE7;

}

/*#tabcontainer>div>div.ui.attached.tabular.menu {
  background: #AFCFE7;
 
}*/
#tabcontainer>div>div.ui.bottom.attached.segment.active.tab {
  background: #E2ECF6;

}

#tabcontainer>div>div.ui.bottom.attached.segment.active.tab>form>button {
  color: #FFFFFF;

}

#tabcontainer>div:nth-child(1)>img {
  max-width: 120px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#tabcontainer>div:nth-child(2)>img {
  max-width: 250px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#root>div>form {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  color: red;
}
