#tabcontainer {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

#stepper {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.presentation-mode {
  max-width: 70vh;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: 800px) {
    margin-top: 15vh;
  }

  @media all and (min-width: 0px) and (max-width: 800px) {
    margin-top: 5vh;
  }

  margin-bottom: auto;
}

#new-tabcontainer {
  margin-left: auto;
  margin-right: auto;
  height: calc(100% - 130px);
  overflow-y: auto;
}

#tabcontainer>div>div.ui.attached.tabular.menu>a.active.item {
  background: #E2ECF6;

}

#tabcontainer>div>div.ui.attached.tabular.menu>a:not(:active) {
  background: #AFCFE7;

}

#tabcontainer>div>div.ui.bottom.attached.segment.active.tab {
  background: #E2ECF6;

}

#tabcontainer>div>div.ui.bottom.attached.segment.active.tab>form>button {
  color: #FFFFFF;

}

#tabcontainer>div:nth-child(1)>img {
  max-width: 120px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#tabcontainer>div:nth-child(2)>img {
  max-width: 250px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#root>div>form {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  color: red;
}

#vizygood-logo {
  padding-top: 20px;
  padding-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#intro-text {
  padding-left: 20px;
  padding-right: 20px;
}

#intro-text {
  text-align: justify;
}

#button-container {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.carousel .slide {
  background: none !important;
  background-image: linear-gradient(#387cc9, #2DA8BE) !important;
  color: white;
}

#gauge {
  max-width: 70vh;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vh;
  margin-bottom: auto;
}

@media (max-width: 800px) and (min-width: 0px) {
  .presentation-mode {
    margin-top: 15vh !important;
  }
}

.CameraIframe {
  border-width: 0px;
  width: 335px;
  height: 250px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

#fatigue-form {
  h2 {
    font-size: 2rem;
    color: #2473a8;
  }

  .good,
  .bad {
    h3 {
      text-transform: uppercase;
      font-size: 2rem;
    }

    h4 {
      text-transform: uppercase;
    }

    margin-top:2em;
    border-radius: 20px;
    padding: 2em 3em;
  }

  .good {
    color: #2473a8;
    background-color: #dff8fc;
  }

  .bad {
    color: #bf2e2e;
    background-color: #fedddd;
  }
}