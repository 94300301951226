div.swiper-slide.swiper-slide-next {
  margin-bottom: 5px;
}

.title-text {
  margin-top: 5px;

  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 16px;
}

.swiper-slide {
  max-width: 250px;
  min-width: 150px;
}

.games-container {
  margin-left: auto;
  margin-right: auto;

  height: 100px;
  margin-top: 10%
}

.title {
  color: #2185d0;
  font-size: 1.4em;
  padding-bottom: .1em;
  margin: 2em 0 .5em;
  border-bottom: solid 1px #2185d0;
}

@media screen and (min-width: 800px) {
  .swiper-slide {
    width: 22vh !important;
  }
}