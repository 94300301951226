.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

html,
body,
#root {
  /* we don't want any of these to scroll */
  overflow: hidden;
  height: 100%;
}

@font-face {
  font-family: "DINNRoundedRegular";
  src: local("DINNRoundedRegular"),
    url("../public/fonts/DINNextRoundedLTPro-Regular.woff") format("woff");
}

@font-face {
  font-family: "DINNRoundedBold";
  src: local("DINNRoundedBold"),
    url("../public/fonts/DINNextRoundedLTPro-Bold.woff") format("woff");
}


/* Customize fullscreen close icon*/
.ui.fullscreen.modal>.close {
  color: white !important;
}